@import "src/styles/helpers";

.case {
  @include pageHeaderPadding;
  overflow: hidden;

}

.hero {
  padding-bottom: var(--margin-xxl);
  padding-top: 124px;


  @include media(landscape) {
    padding-top: 184px;
  }

  @include media(desktopAll) {
    display: grid;
    grid-template-columns: 568px 1fr;
    gap: 0 96px;
    padding-top: 184px;
  }

  .title {
    position: relative;
    margin-bottom: var(--margin-xxl);

    @include media(landscape) {
      line-height: 56px;
      font-size: 48px;
    }

    @include media(desktopAll) {
      margin-bottom: 0;
      line-height: 64px;
    }

    .titleButton {
      display: none;
      margin-right: 10px;

      @include media(landscape) {
        display: inline-flex;
        float: inline-start;
      }

      @include media(desktopAll) {
        display: inline-flex;
        float: inline-start;
      }

      &_mobile {
        display: flex;
        margin-top: var(--margin-sm);
        margin-right: 0;

        @include media(landscape) {
          display: none;
        }

        @include media(desktopAll) {
          display: none;
        }
      }
    }
  }

  &__image {
    grid-column: 1 / 3;
    margin-top: var(--margin-xl);
    margin-bottom: var(--margin-xl);

    @include media(tablet) {
      margin-top: 96px;
      margin-bottom: 96px;
    }

    @include media(desktopAll) {
      margin-top: 156px;
      margin-bottom: 156px;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: var(--margin-md);
    margin-bottom: var(--margin-lg);
  }

  &__tag {
    font-family: 'KharkivTone', sans-serif;
    font-size: 14px;
    line-height: 1;
    @include setProperty(color, var(--secondary-50), var(--secondary-500));

    &:hover {
      @include setProperty(color, var(--primary-500), var(--primary-900));
    }
  }

  &__text {
    font-weight: 300;
    font-size: 16px;
    letter-spacing: var(--ls-183);
    line-height: 2;

    p {
      margin-bottom: 32px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.projectDetails {
  grid-column: 1 / 3;
  display: flex;
  gap: var(--margin-lg);
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 56px;
  border-bottom: 1px solid;
  @include setProperty(border-color, var(--dark-line-low-accent), var(--light-line-low-accent));
}

.projectDetail {
  text-align: center;
  margin: 0 auto;

  &__title {
    font-size: 14px;
    line-height: 2;
    margin-bottom: var(--margin-xs);
    font-weight: 300;
  }

  &__value {
    font-family: 'KharkivTone', sans-serif;
    font-size: 16px;
    line-height: 24px;
  }
}